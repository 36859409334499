// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    setDoc,
    updateDoc,
    deleteDoc,
    serverTimestamp,
    getFirestore,
    writeBatch,
    query, where, Timestamp, onSnapshot, collectionGroup, limit, startAfter, getCountFromServer
} from "firebase/firestore";

import { deleteObject, getDownloadURL, getStorage, list, ref, uploadBytesResumable } from "firebase/storage";
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    sendPasswordResetEmail,
    updatePassword,
    confirmPasswordReset
    // deleteUser
} from "firebase/auth";
import { toast } from "react-toastify";
import { storage } from "../utils";
import { COLLECTION } from "../constants/firebase-colloection";
import { getFunctions } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    cloudFunctionsUrl: process.env.REACT_APP_CLOUD_FUNCTION_URL,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebaseFunctions = getFunctions();
export const db = getFirestore(app);
const auth = getAuth(app);
const userAuth = getAuth(app);
export const firebaseStorage = getStorage(app);

// Firestore service methods
const firestoreService = {
    snapshotDocuments(collectionName, callback, options) {
        let constraints = [];
        if (options?.query2?.field) {
            constraints.push(where(options?.query2?.field, options?.query2?.operator, options?.query2?.value));
        }
        const q = query(collection(db, collectionName), ...constraints, ...(options?.lastVisible ? [limit(options?.limit), startAfter(options?.lastVisible)] : options?.limit ? [limit(options.limit)] : []));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const documents = [];
            querySnapshot.forEach((doc) => {
                if (doc.exists()) {
                    const docData = { uid: doc.id, ...doc.data() };
                    documents.push(docData);
                }
            });
            const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            callback(options?.limit ? { documents, lastVisible } : documents);
        });

        return unsubscribe; // Return the unsubscribe function to stop listening when needed
    },

    async getCount(collectionName, queryArray, callback) {
        const q = query(collection(db, collectionName), ...queryArray?.map((_query) => where(_query?.field, _query?.operator, _query?.value)))
        const snapshot = await getCountFromServer(q);
        let count = snapshot.data().count;
        callback(count);
    },

    async getCollectionCount(collectionName, queryArray, callback) {
        const q = query(collectionGroup(db, collectionName), ...queryArray?.map((_query) => where(_query?.field, _query?.operator, _query?.value)))
        const snapshot = await getCountFromServer(q);
        let count = snapshot.data().count;
        callback(count);
    },

    snapshotDocument(collectionName, _query, callback, options) {
        const constraints = [where(_query?.field, _query?.operator, _query?.value)];
        if (options?.query2?.field) {
            constraints.push(where(options?.query2?.field, options?.query2?.operator, options?.query2?.value));
        }
        const q = query(collection(db, collectionName), ...constraints, ...(options?.lastVisible ? [limit(options?.limit), startAfter(options?.lastVisible)] : options?.limit ? [limit(options.limit)] : []));

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            const documents = querySnapshot.docs.map(doc => ({ uid: doc.id, ...doc.data() }));
            const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            callback(options?.limit ? { documents, lastVisible } : documents);
        });

        return unsubscribe; // Return the unsubscribe function to stop listening when needed
    },

    getAllDocumentsWithQuery(collectionName, array) {
        let responseArray = [];
        array?.forEach(async (data) => {
            const ref = data?.id;
            const q = query(collection(db, collectionName), where('uid', '==', ref));
            try {
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    const response = doc.data();
                    responseArray.push(response);
                });
            } catch (error) {
                console.error('Error fetching employee data:', error);
            }
        });
        return responseArray;
    },

    async addDocument(collectionName, data) {
        try {
            const timestamp = serverTimestamp();
            const dataWithTimestamp = { ...data, createdAt: timestamp, updatedAt: timestamp };
            const docRef = await addDoc(collection(db, collectionName), dataWithTimestamp);
            // Update the document with the actual doc().id
            await setDoc(doc(db, collectionName, docRef.id), { uid: docRef.id }, { merge: true });
            return { uid: docRef.id, ...dataWithTimestamp };
        } catch (error) {
            console.error('Error adding document: ', error);
            return null;
        }
    },

    async setDocument(collectionName, documentId, data) {
        try {
            const timestamp = serverTimestamp(); // Get the current server timestamp
            const dataWithTimestamp = { ...data, createdAt: timestamp, updatedAt: timestamp };
            await setDoc(doc(db, collectionName, documentId), dataWithTimestamp);
            return { ...dataWithTimestamp };
        } catch (error) {
            console.error('Error setting document: ', error);
            return null;
        }
    },

    async getDocument(collectionName, documentId) {
        try {
            if (!documentId)
                return null;
            const docRef = doc(db, collectionName, documentId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                return { uid: docSnap.id, ...docSnap.data() };
            }
            console.log('No such document!');
            return null;
        } catch (error) {
            console.error('Error getting document: ', error);
            return null;
        }
    },
    async getDocumentsByReference(array) {
        let response = await Promise.all(
            array?.map(async (ref) => {
                const docSnap = await getDoc(ref);
                if (docSnap.exists()) {
                    return docSnap.data();
                } else {
                    return {};
                }
            })
        );
        return response?.filter((value) => Object.keys(value)?.length);
    },

    async getDocumentCountByReference(reference, collectionName) {
        try {
            const querySnapshot = await getDocs(collection(db, collectionName).where('address_ref', '==', reference));
            return querySnapshot.size;
        } catch (error) {
            console.error('Error getting document count:', error);
            throw error;
        }
    },

    async getAllDocuments(collectionName, options) {
        try {
            const q = query(collection(db, collectionName), ...(options?.lastVisible ? [limit(options?.limit), startAfter(options?.lastVisible)] : options?.limit ? [limit(options.limit)] : []));
            const querySnapshot = await getDocs(q);
            const documents = querySnapshot.docs.map(doc => ({ uid: doc.id, ...doc.data() }));
            const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            return options?.limit ? { documents, lastVisible } : documents;
        } catch (error) {
            console.error('Error getting documents: ', error);
            return null;
        }
    },

    async updateDocument(collectionName, documentId, data) {
        try {
            const timestamp = serverTimestamp(); // Get the current server timestamp
            const dataWithTimestamp = { ...data, updatedAt: timestamp };
            if (typeof data.dob == "string") {
                const [day, month, year] = data.dob.split(".")
                const date = new Date(`${month}/${day}/${year}`)
                dataWithTimestamp.dob = date
            }
            await updateDoc(doc(db, collectionName, documentId), dataWithTimestamp);
            return dataWithTimestamp;
        } catch (error) {
            console.error('Error updating document: ', error);
            return false;
        }
    },

    async deleteDocument(collectionName, documentId) {
        try {
            await deleteDoc(doc(db, collectionName, documentId));
            return true;
        } catch (error) {
            console.error('Error deleting document: ', error);
            return false;
        }
    },

    async deleteDocuments(collectionName, documents) {
        try {
            const batch = writeBatch(db);
            documents.forEach(({ uid }) => {
                const docRef = doc(db, collectionName, uid);
                batch.delete(docRef);
            });
            await batch.commit();
            return true;
        } catch (error) {
            console.error(error)
            return false;

        }
    },

    async findOneDocument(collectionName, condition) {
        try {
            const q = query(collection(db, collectionName), where(condition?.field, condition?.operator, condition?.value));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.docs.length === 0) {
                return null;
            }
            const docSnap = querySnapshot.docs[0];
            if (docSnap.exists()) {
                return { uid: docSnap.id, ...docSnap.data() };
            }
            return null;
        } catch (error) {
            console.error("Error querying collection: ", error);
            return null;
        }
    },

    async findDocuments(collectionName, condition, condition2 = false, options = {}) {
        try {
            const constraints = [where(condition?.field, condition?.operator, condition?.value)];
            if (!!condition2) {
                constraints.push(where(condition2?.field, condition2?.operator, condition2?.value));
            }
            if (!!Object.keys(options)?.length) {
                constraints.push(limit(options?.limit))
            }
            const q = query(collection(db, collectionName), ...constraints);
            const querySnapshot = await getDocs(q);
            const documents = [];
            querySnapshot.forEach((doc) => {
                if (doc.exists()) {
                    const docData = { uid: doc.id, ...doc.data() };
                    documents.push(docData);
                }
            });
            const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            return Object.keys(options).length ? { documents, lastVisible } : documents;
        } catch (error) {
            console.error("Error querying collection: ", error);
            return [];
        }
    },

    getDocumentReference(collectionName, documentId) {
        return doc(db, collectionName, documentId);
    },

    getAllDocumentsReference(collectionName, array) {
        return array?.map((item) => {
            return item?.id ? item : firestoreService.getDocumentReference(collectionName, item?.uid ? item?.uid : item);
        });
    },

    async addBatchDocs(collectionName, array) {
        try {
            const batch = writeBatch(db);
            const collectionRef = collection(db, collectionName);
            let refs = array.map((data) => {
                const newDocRef = doc(collectionRef);
                batch.set(newDocRef, { ...data, uid: newDocRef?.id });
                return newDocRef;
            });
            await batch.commit();
            return refs;
        } catch (error) {
            console.error('Error performing batch write:', error);
        }
    },
    async updateBatchDocs(collectionName, array) {
        try {
            let data = []
            const batch = writeBatch(db);
            const collectionRef = collection(db, collectionName);
            const refs = array.map((update) => {
                if (update?.uid) {
                    const docRef = doc(collectionRef, update.uid);
                    const timestamp = serverTimestamp();
                    const dataWithTimestamp = { ...update, updatedAt: timestamp };
                    data.push(dataWithTimestamp);
                    batch.update(docRef, dataWithTimestamp);
                    return docRef;
                } else {
                    const newDocRef = doc(collectionRef);
                    batch.set(newDocRef, { ...update, uid: newDocRef?.id });
                    data.push({ ...update, uid: newDocRef?.id });
                    return newDocRef;
                }
            });
            await batch.commit();
            return refs;
        } catch (error) {
            console.error('Error performing batch update:', error);
        }
    },
    async getDocumentsCountByCollectionGroup(collectionName, conditions) {
        const constraints = conditions.map((condition) => where(condition?.field, condition?.operator, condition?.value));
        const q = query(collectionGroup(db, collectionName), ...constraints);
        const querySnapshot = await getCountFromServer(q);
        return querySnapshot.data().count;
    },
    
    async getDocumentsByCollectionGroup(collectionName, conditions) {
        const constraints = conditions.map((condition) => where(condition?.field, condition?.operator, condition?.value));
        const q = query(collectionGroup(db, collectionName), ...constraints);
        const querySnapshot = await getDocs(q);
        const documents = [];
        querySnapshot.forEach((doc) => {
            if (doc.exists()) {
                const docData = { uid: doc.id, ...doc.data() };
                documents.push(docData);
            }
        });

        return documents;
    },
}


const storageServices = {
    async uploadMultipleFiles(folderName, id, filesArray) {
        const storageRef = ref(firebaseStorage, `${folderName}/${id}`);
        const timestamp = Timestamp.now();

        const fileDataArray = await Promise.all(
            filesArray.map(async (file, index) => {
                const fileRef = ref(storageRef, `${file.name}`);

                try {
                    // Upload the file
                    await uploadBytesResumable(fileRef, file);

                    // Get the download URL for the file
                    const downloadURL = await getDownloadURL(fileRef);

                    // Collect data about the file
                    const fileData = {
                        fileName: file.name,
                        size: file.size,
                        fileLink: downloadURL,
                        updatedAt: timestamp,
                        createdAt: timestamp,
                        fileId: Date.now(),
                    };
                    return fileData;
                } catch (error) {
                    console.error(`Error uploading file ${index + 1}: ${error}`);
                    return null; // Return null for failed uploads
                }
            })
        );

        // Filter out any null values (failed uploads) from the array
        const successfulUploads = fileDataArray.filter((fileData) => fileData !== null);
        return successfulUploads;
    },

    async deleteFolderContentsAndFolder(folderRef) {
        try {
            // List all items (files and subfolders) in the folder
            const result = await list(folderRef);
            const deletePromises = result.items.map(async (itemRef) => {
                // Delete individual file
                try {
                    await deleteObject(itemRef);
                } catch (error) {
                    console.error('Error deleting file:', itemRef.name, error);
                }
            });
            // Wait for all files and subfolders to be deleted
            await Promise.all(deletePromises);

        } catch (error) {
            console.error('Error deleting folder and its contents:', folderRef.name, error);
            return false;
        }
    },

    async deleteFile(folderId, file) {

        const storage = getStorage();

        // // Create a reference to the file to delete
        const desertRef = ref(storage, `files/${folderId}/${file?.fileName}`);

        // Delete the file
        await deleteObject(desertRef).then(() => {
            return true;
        }).catch((error) => {
            console.error(error)
            return false;
        });
        return true;
    }
};

// User management service methods
const userService = {
    async userExists(collection, condition) {
        try {
            const user = await firestoreService.findOneDocument(collection, condition);
            return user !== null;

        } catch (error) {
            console.error("Error checking user existence: ", error);
            return false;
        }
    },
      async getUserByEmail(email) {
          try {
            const response = await fetch(`${firebaseConfig.cloudFunctionsUrl}/getUserByEmail?email=${email}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (response.ok) {
                return response.json();
            } else {
                console.error('Error getting user:', response.statusText);
            }
        } catch (error) {
            console.error('Error getting user:', error.message);
        }
    },


    async createEmployeeAndAssociateUID(collection, employeeData) {
        try {
            // Check if the user already exists based on their email
            const emailExist = await this.userExists(collection, { field: "email", operator: "==", value: employeeData.email });
            if (emailExist) {
                return null;
            }

            // User does not exist, proceed with user creation
            const { email, password } = employeeData;
            // let newPassword = await encryptPassword(password)
            let uid;
            try {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                uid = userCredential.user.uid;
            } catch (error) {
                if (error.code === 'auth/email-already-in-use') {
                    const user = await this.getUserByEmail(email);
                    uid = user.user.uid;
                } else {
                    throw error;
                }
            }

            // delete password from employeeData
            delete employeeData.password;

            const employeeDocumentData = {
                ...employeeData,
                uid: uid,
            };

            const adminUserPayload = {
                ...employeeData,
                employeeId: uid,
                password: employeeData?.password,
            }

            // Update the Firestore document with the UID
            const employeeDocRef = await firestoreService.setDocument(collection, uid, employeeDocumentData);
            // console.log("employeeDocRef", employeeDocRef);
            await firestoreService.addDocument(COLLECTION.AdminUser, adminUserPayload);
            return employeeDocRef;
        } catch (error) {
            console.error("Error creating employee:", error);
            return null;
        }
    },
    async changePasswordAndUpdateFirestore(collectionName, userData) {
        try {
            const { email, currentPassword, newPassword } = userData;
            // Sign in with the provided email and password
            const userCredential = await signInWithEmailAndPassword(userAuth, email, currentPassword);
            // Delete the user from Firebase Authentication
            // let _newPassword = await encryptPassword(newPassword)
            await updatePassword(userCredential.user, newPassword);
            // Update the user's document from Firestore
            let newPasswordObject = { password: newPassword };
            const updatedUser = await firestoreService.updateDocument(collectionName, userCredential.user.uid, newPasswordObject)
            await signOut(userAuth);
            return updatedUser;
        } catch (error) {
            console.error("Error deleting user:", error);
            return false;
        }
    },
    async deleteUser(uid) {
        try {
            const response = await fetch(`https://${firebaseConfig.projectId}.firebaseio.com/deleteUser?uid=${uid}`);
            if (response.ok) {
                console.log('User successfully deleted');
            } else {
                console.error('Error deleting user:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting user:', error.message);
        }
    },


    async deleteUserFromAuthAndFirestore(collection, userData) {
        try {
            const { email, password, uid } = userData;
            // Sign in with the provided email and password
            // const userCredential = await signInWithEmailAndPassword(userAuth, email, password);
            const user = auth.currentUser;
            // Delete the user from Firebase Authentication
            this.deleteUser(user).then(async () => {
                console.log("user deleted from firebase auth")
                // Delete the user's document from Firestore               
                const docRef = doc(db, collection, uid);
                await deleteDoc(docRef);
            }).catch((error) => {
                console.error("Error deleting user:", error);
                return false;
            });
            return true;
        } catch (error) {
            console.error("Error deleting user:", error);
            return false;
        }
    },
    async adminLogin(collection, adminData) {
        try {
            const { email, password } = adminData;
            // Sign in with the provided email and password
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            let user = userCredential.user;
            let q = { field: "uid", value: user?.uid, operator: "==" };
            const userDocs = await firestoreService.findOneDocument(collection, q);
            if (!!userDocs) {
                if (['admin'].includes(userDocs?.role)) {
                    // Save the access token (optional)
                    const idTokenResult = await user.getIdTokenResult();
                    storage('create', 'accessToken', idTokenResult.token)

                    // Set up an event listener to refresh the token
                    onAuthStateChanged(auth, (user) => {
                        if (user) {
                            // Refresh the access token when needed (e.g., before it expires)
                            user.getIdToken(true)
                                .then((token) => {
                                    storage('create', 'accessToken', token)
                                })
                                .catch((error) => {
                                    console.error('Error refreshing access token:', error);
                                });
                        }
                    });

                    let User = { ...userDocs };
                    delete User.password
                    if (!!User) {
                        const userData = {
                            authUser: user,
                            userData: User,
                        };
                        // let newPassword = encryptPassword(password);
                        // await firestoreService.updateDocument(collection, user.uid, { password });
                        // Save the custom object to local storage
                        storage('create', 'currentUser', userData);
                        toast.success("Login Successfully");
                        return userData;
                    } else {
                        toast.error("User not found");
                        storage('clear');
                    }
                }
                else {
                    toast.error("No Access found");
                }
            } else {
                toast.error("User not found.");
            }

        } catch (error) {
            console.error('Error logging in with admin user:', error);

            if (error.code === 'auth/wrong-password') {
                toast.error('Wrong password. Please try again.');
            } else if (error.code === 'auth/user-not-found') {
                toast.error('User not found. Please check your email.');
            } else {
                toast.error(error.message);
            }

            return null;
        }
    },
    async adminLogout(navigate) {

        // Sign out the user
        signOut(auth)
            .then(() => {
                storage('clear');
                navigate(0);
            })
            .catch((error) => {
                console.error('Error logging out:', error);
            });
    },
    async SendEmailForResetPassword(email) {
        try {
            const actionCode = {
                url: `${process.env.REACT_APP_LOGIN_URL}`,
            };
            await sendPasswordResetEmail(auth, email, actionCode);
            toast.success('Reset email sent. Check your inbox!');
            return true;
        } catch (error) {
            console.error('Error sending reset email:', error);
            return false;
        }
    },
    async confirmPasswordReset(collectionName, oobCode, userData) {
        try {
            const { email, newPassword } = userData;
            await confirmPasswordReset(auth, oobCode, newPassword);
            const userCredential = await signInWithEmailAndPassword(userAuth, email, newPassword);

            // Update the user's document from Firestore
            let newPasswordObject = { password: newPassword };
            // let newPasswordObject = { password: await encryptPassword(newPassword) };
            await firestoreService.updateDocument(collectionName, userCredential.user.uid, newPasswordObject)
            await signOut(userAuth);
            toast.success('Password reset successfully!')
            return true;
        } catch (error) {
            console.error('Error confirming password reset:', error);
            switch (error.code) {
                case 'auth/expired-action-code':
                    toast.error('Link expired. Request a new one.');
                    break;
                case 'auth/invalid-action-code':
                    toast.error('Invalid link. Request a new one.');
                    break;
                case 'auth/user-disabled':
                    toast.error('Account disabled. Contact support.');
                    break;
                case 'auth/user-not-found':
                    toast.error('User not found or link incorrect. Check the link or request a new one.');
                    break;
                case 'auth/weak-password':
                    toast.error('Weak password. Use at least 8 characters with numbers and symbols.');
                    break;
                default:
                    toast.error('Unexpected error. Try again later.');
            }
        }
    },
}



export { firestoreService, userService, storageServices };